<template>
  <div v-loading="loading" class="areaSetting">
    <div class="title">基础统计设置/区域统计设置</div>
    <a-alert
      type="error"
      message="注意：区域自身的统计不受“不统计的学校”影响"
      banner
      show-icon
      closable
    >
      <template slot="icon">
        <a-icon type="warning" theme="filled" />
      </template>
    </a-alert>
    <div class="operate">
      <a-space>
        <a-button type="primary" @click="add">创建</a-button>
        <a-button type="primary" ghost @click="importFile">导入</a-button>
        <a-button type="primary" ghost @click="exportFile">导出</a-button>
      </a-space>
      <a-button type="danger" @click="clear">
        <img
          src="@/assets/uExam/clear-white.png"
          style="width: 14px; margin-right: 4px"
          alt=""
        />
        清空
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="false"
      @change="tablePaginationChange"
    >
      <template slot="actions" slot-scope="text, record">
        <a-button type="link" @click="edit(record)">修改</a-button>
        <a-button type="link" style="color: #f56c6b" @click="del(record)"
          >删除</a-button
        >
      </template>
      <template slot="schoolName" slot-scope="text, record">
        <div class="schoolList">
          <div
            v-for="(item, index) in record.schools"
            :key="index"
            class="school"
          >
            {{ item.name }}
          </div>
        </div>
      </template>
    </a-table>
    <a-modal
      v-model="visible"
      :title="title"
      :width="modalStatus[title]?.width"
      :ok-text="modalStatus[title]?.okText"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancle"
    >
      <template v-if="['创建区域统计', '修改区域统计'].includes(title)">
        <a-form-model ref="form" :rules="rules" :model="form">
          <a-form-model-item prop="name">
            <a-input
              v-model="form.name"
              allow-clear
              placeholder="请输入区域名称"
            ></a-input>
          </a-form-model-item>
        </a-form-model>
        <div class="transfer">
          <a-transfer
            :data-source="transferData"
            style="text-align: center"
            :target-keys="targetKeys"
            :selected-keys="selectedKeys"
            :render="(item) => item.title"
            :list-style="{
              width: '46%',
              height: '400px',
            }"
            @change="handleChange"
            @selectChange="handleSelectChange"
          />
        </div>
      </template>
      <template v-if="title === '导入区域设置'">
        <ImportUpload
          :key="uploadKey"
          tips="请根据《学校信息表》填写《区域统计表》，并确保学校名称完全一致"
          :file-model="{
            name: '区域统计表.xls',
            url: 'https://static.wtjy.com/stat/template/%E5%AF%BC%E5%85%A5%E7%BB%9F%E8%AE%A1%E5%8C%BA%E5%9F%9F%E6%A8%A1%E6%9D%BF.xls',
          }"
          :disabled="confirmLoading"
          @change="uploadChange"
        >
          <template slot="operate">
            <span>
              下载数据：<a-button type="link" @click="downloadSchoolMsg"
                >学校信息.xls</a-button
              ></span
            >
          </template>
        </ImportUpload>
      </template>
    </a-modal>
  </div>
</template>

<script>
import ImportUpload from "@/components/ImportUpload";
import { getstatschooloption } from "@/core/api/academic/common";
import {
  getstatareasettinglist,
  insertstatareasetting,
  savestatareasetting,
  deletestatareasetting,
  clearstatareasetting,
  downloadstatschool,
  importstatareasetting,
  exportstatareasetting,
} from "@/core/api/academic/union";
export default {
  name: "",
  components: {
    ImportUpload,
  },
  data() {
    return {
      confirmLoading: false,
      targetKeys: [],
      selectedKeys: [],
      transferData: [],
      loading: false,
      form: {},
      rules: {
        name: [{ required: true, message: "请输入区域名称", trigger: "blur" }],
      },
      title: "",
      tableData: [],
      columns: [
        {
          title: "区域名称",
          align: "center",
          dataIndex: "name",
          width: 120,
        },
        {
          title: "所属学校",
          align: "center",
          dataIndex: "schoolName",
          scopedSlots: { customRender: "schoolName" },
        },
        {
          title: "操作",
          align: "center",
          width: 180,
          dataIndex: "actions",
          scopedSlots: { customRender: "actions" },
        },
      ],
      total: 0,
      search: {
        current: 1,
        size: 20,
      },
      modalStatus: {
        创建区域统计: {
          width: "45%",
          okText: "保存",
        },
        修改区域统计: {
          width: "45%",
          okText: "保存",
        },
        导入区域设置: {
          width: "35%",
          okText: "导入区域设置",
        },
      },
      visible: false,
      fileList: null,
      uploadKey: Math.random(),
    };
  },
  mounted() {
    this.getSchoolList();
    this.getData();
  },
  methods: {
    async downloadSchoolMsg() {
      const res = await downloadstatschool({ id: this.$route.query.id });
      const { href } = this.$router.resolve({
        path: "/downFile",
        query: {
          url: res.data.data.url,
          name: res.data.data.name,
          type: 1,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    async getSchoolList() {
      const res = await getstatschooloption({ id: this.$route.query.id });
      this.transferData = res.data.data.map((item) => ({
        title: item.name,
        key: item.id.toString(),
        description: item.name,
        disabled: false,
      }));
    },
    handleChange(nextTargetKeys) {
      this.targetKeys = nextTargetKeys;
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getstatareasettinglist({ id: this.$route.query.id });
        this.tableData = res.data.data;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    edit(record) {
      this.form = { ...record };
      this.targetKeys = record.schools.map((item) => item.id.toString());
      this.title = "修改区域统计";
      this.visible = true;
    },
    del(record) {
      this.$confirm("确定删除区域统计吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deletestatareasetting({ id: record.id });
          this.getData();
          this.$message({
            type: "success",
            message: "区域统计设置已删除。",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    clear() {
      this.$confirm("确定清空区域统计吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await clearstatareasetting({ id: this.$route.query.id });
          this.getData();
          this.$message({
            type: "success",
            message: "已提交",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    add() {
      this.title = "创建区域统计";
      this.visible = true;
    },
    importFile() {
      this.title = "导入区域设置";
      this.visible = true;
    },
    async exportFile() {
      const res = await exportstatareasetting({ id: this.$route.query.id });
      const { href } = this.$router.resolve({
        path: "/downFile",
        query: {
          url: res.data.data.url,
          name: res.data.data.name,
          type: 1,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    handleOk() {
      const func = {
        创建区域统计: this.addArea,
        修改区域统计: this.editArea,
        导入区域设置: this.importSubmit,
      };
      func[this.title]();
    },
    uploadChange({ fileList }) {
      this.fileList = fileList[0];
    },
    async importSubmit() {
      if (this.fileList) {
        let data = new FormData();
        data.append("file", this.fileList.originFileObj);
        try {
          this.confirmLoading = true;
          const res = await importstatareasetting({
            id: this.$route.query.id,
            file: data,
          });
          if (res.data.data.status === 1) {
            this.$message({
              type: "warning",
              message: res.data.data.name,
              showClose: true,
            });
          } else {
            this.$message({
              type: "success",
              message: "提交成功",
              showClose: true,
            });
          }
          this.fileList = [];
          this.uploadKey = Math.random();

          this.visible = false;
          this.confirmLoading = false;
          this.getData();
        } catch {
          this.confirmLoading = false;
        }
      } else {
        this.$message({
          type: "waring",
          message: "请选择需要导入的文件",
          showClose: true,
        });
      }
    },
    addArea() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.targetKeys.length === 0) {
            this.$message({
              showClose: true,
              message: "请选择区域所包含的学校",
              type: "warning",
            });
            return false;
          }
          this.confirmLoading = true;
          try {
            await insertstatareasetting({
              statId: this.$route.query.id,
              id: null,
              name: this.form.name,
              schoolIds: this.targetKeys.join(","),
            });
            this.confirmLoading = false;
            this.form.name = "";
            this.targetKeys = [];
            this.visible = false;
            this.getData();
            this.$message({
              showClose: true,
              message: "提交成功",
              type: "success",
            });
          } catch {
            this.confirmLoading = false;
          }
        }
      });
    },
    editArea() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.confirmLoading = true;
          try {
            await savestatareasetting({
              statId: this.$route.query.id,
              id: this.form.id,
              name: this.form.name,
              schoolIds: this.targetKeys.join(","),
            });
            this.confirmLoading = false;
            this.visible = false;
            this.form.name = "";
            this.targetKeys = [];
            this.getData();
            this.$message({
              showClose: true,
              message: "提交成功",
              type: "success",
            });
          } catch {
            this.confirmLoading = false;
          }
        }
      });
    },
    handleCancle() {
      this.visible = false;
    },
    tablePaginationChange(val) {
      if (this.search.size !== val.pageSize) {
        this.search.current = 1;
        this.search.size = val.pageSize;
      } else {
        this.search.current = val.current;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.areaSetting {
  .title {
    color: #0a1119;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 18px;
  }
  .tips {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      width: 14px;
    }
  }
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 18px 0;
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 18px;
  }
}
::v-deep .ant-transfer-list-header {
  text-align: left !important;
}
::v-deep .ant-transfer-list-content-item {
  text-align: left !important;
}
.schoolList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .school {
    margin-right: 8px;
  }
}
::v-deep .ant-transfer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
