import { render, staticRenderFns } from "./areaSetting.vue?vue&type=template&id=721c17be&scoped=true&"
import script from "./areaSetting.vue?vue&type=script&lang=js&"
export * from "./areaSetting.vue?vue&type=script&lang=js&"
import style0 from "./areaSetting.vue?vue&type=style&index=0&id=721c17be&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "721c17be",
  null
  
)

export default component.exports